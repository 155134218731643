import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactPage from '../components/contactPage';

class RedirectIndex extends React.PureComponent {
  constructor(args) {
    super(args);
  }

  render() {
    const { data } = this.props;
    const { seo, contact, team } = data.contactDeJson;

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <SEO {...seo} />

        <ContactPage
          contact={contact}
          team={team}
        />
      </Layout>
    );
  }
}

export default RedirectIndex;

export const pageQuery = graphql`
    query ContactDeQuery {
        site{
            siteMetadata{
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        contactDeJson {
            seo {
                title
                description
                lang
                keywords
            }
            contact {
                preHeader
                header
                content
                email
                phone
                address
            }
            team {
                preHeader
                header
                member {
                    name
                    job
                    image {
                        childImageSharp {
                            fixed(width: 200 height: 200 quality: 100) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;
