import React, { Component } from 'react';
import 'intl';
import { Container } from 'reactstrap';

import ContactForm from './contactForm';
import TeamCard from './teamCards';
import Map from './map';
import SocialLinks from './socialLinks';


import '../assets/scss/pages/_contactPage.scss';

class contactPage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { team, contact } = this.props;
    return (
      <div className="contact-wrapper">
        <section className="intro text-center">
          <div
            className="position-absolute social-links-wrapper"
          >
            <SocialLinks/>
          </div>
          <h1>
            <span>
              {contact.preHeader}
            </span>
            {contact.header}
          </h1>
        </section>

        <section className="contact-section first oblique">
          <Container
            className="oblique-content"
          >
          </Container>
        </section>

        <ContactForm {...contact} />
        <TeamCard content={{header: team.header, preHeader: team.preHeader}} items={team.member}/>
        <Map/>
      </div>
    );
  }
}

export default contactPage;
