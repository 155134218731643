import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';


class TeamCard extends Component {
  render() {
    return (
      <section className='team-card-section oblique'>
        <Container className="oblique-content">
          <Row className="text-center">
            <Col>
              <h2>
                <span>{this.props.content.preHeader}</span>
                {this.props.content.header}
              </h2>
            </Col>
          </Row>
          <Row>
            {this.props.items.map((item, index) => (
              <Col lg={6} sm={6} className="g-mb-30" key={index}>
                <figure className="text-center">
                  <div className="d-block mx-auto rounded-circle g-max-width-200 g-bg-white g-pa-5 g-mb-15">
                    <Img className="rounded-circle g-max-width-190" fixed={item.image.childImageSharp.fixed}/>
                  </div>

                  <div className="team-card-content">
                    <h4 className="h4">{item.name}</h4>
                  <em className="d-block">{item.job}</em>
                  </div>
                </figure>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
}

TeamCard.defaultProps = {
  items: []
};

TeamCard.propTypes = {
  items: PropTypes.object
};

export default TeamCard;
