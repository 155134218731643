import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import '../assets/scss/components/_contactForm.scss';

const axios = require('axios');

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertType: null
    };

    this.initialValues = {
      name: '',
      email: '',
      phone: '',
      message: ''
    };

    this.sendEmail = this.sendEmail.bind(this);
    this.getAlerterClass = this.getAlerterClass.bind(this);
  }


  sendEmail(payload) {
    return axios.post('https://www.impect.com/api/contact', {
      name: payload.name || '',
      phone: payload.phone || '',
      email: payload.email || '',
      message: payload.message || ''
    });
  }

  getAlerterClass(msg) {
    if (msg === 'successful') {
      return 'success';
    }
    return 'danger';
  }


  render() {
    const { email, phone, address, content } = this.props;

    const schema = Yup.object().shape({
      name: Yup.string()
        .required('required'),
      email: Yup.string()
        .email('invalidEmail')
        .required('required'),
      message: Yup.string()
        .required('required')
    });

    const CustomInputComponent = ({
      field,
      form: { touched, errors },
      ...props
    }) => (
      <FormGroup>
        <FormattedMessage id={`placeholder-${field.name}`}>
          {placeholder => (
            <Input
              className={{
                'form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus rounded-0 g-py-13 g-px-15': true,
                'g-bg-red-opacity-0_1 g-color-lightred': touched[field.name] && errors[field.name],
                'g-resize-none g-height-100': props.type === 'textarea'
              }}
              id={field.name}
              type="text"
              placeholder={placeholder}
              {...field}
              {...props}
            />
          )}
        </FormattedMessage>
        {touched[field.name] && errors[field.name] ? (
          <div className="mt-1 text-danger">
            <FormattedMessage id={errors[field.name]}/>
          </div>
        ) : null}
      </FormGroup>
    );


    return (
      <section className="content-form-section">
        <Container>
          <Row>
            <Col md={7}>
              <Formik
                initialValues={this.initialValues}
                validationSchema={schema}
                onSubmit={(values, formControls) => {
                  this.sendEmail(values, formControls)
                    .then((response) => {
                      if (response.statusCode) {
                        formControls.setSubmitting(true);
                        this.setState({ alertType: 'successful' });
                      } else {
                        formControls.setSubmitting(false);
                        this.setState({ alertType: 'error' });
                      }
                      return response;
                    })
                    .catch((error) => {
                      console.log(error);
                      formControls.setSubmitting(false);
                      this.setState({ alertType: 'error' });
                      return error;
                    });
                }}
              >
                {({
                  handleSubmit
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {this.state.alertType ? (
                        <Col md={12}>
                          <Alert color={this.getAlerterClass(this.state.alertType)}>
                            {this.state.alertType === 'successful' ? <FormattedMessage id="successfulSend"/> : null}
                            {this.state.alertType === 'error' ? <FormattedMessage id="somethingIsWrong"/> : null}
                          </Alert>
                        </Col>
                      ) : null}
                      <Col md={12} className="form-group uneditable-input">
                        <Field name="name" component={CustomInputComponent}/>
                      </Col>

                      <Col md={6} className="form-group uneditable-input">
                        <Field name="phone" component={CustomInputComponent}/>
                      </Col>

                      <Col md={6} className="form-group uneditable-input">
                        <Field name="email" component={CustomInputComponent}/>
                      </Col>

                      <Col md={12} className="form-group uneditable-input">
                        <Field name="message" type="textarea" component={CustomInputComponent}/>
                      </Col>
                    </Row>

                    <Button color="primary" className="" type="submit">
                      <FormattedMessage id={'sendMessage'}/>
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>

            <Col md={5}>
              <div className="mb-4 mt-3 mt-md-0">
                <p>{content}</p>
              </div>

              <address className="text-uppercase mb-0">
                <div className="d-block text-left">
                  <div className="dot">
                    <i className="far fa-building"/>
                  </div>
                  <div className="after-dot-text">
                    <FormattedMessage id="contactAddress"/>
                    <br/>
                    <div>{address}</div>
                  </div>
                </div>

                <div className="d-block text-left mt-4">
                  <div className="dot">
                    <i className="far fa-envelope-open"/>
                  </div>
                  <div className="after-dot-text">
                    <FormattedMessage id="contactEmail"/>
                    <br/>
                    <a className="" href={`mailto:${email}`}>
                      {email}
                    </a>
                  </div>
                </div>

                <div className="d-block text-left mt-4">
                  <div className="dot">
                    <i className="fas fa-phone"/>
                  </div>
                  <div className="after-dot-text">
                    <FormattedMessage id="contactPhoneNumber"/>
                    <br/>
                    <a className="" href={`tel:${phone}`}>
                      {phone}
                    </a>
                  </div>
                </div>
              </address>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

ContactForm.defaultProps = {
  email: '',
  phone: '',
  address: ''
};

ContactForm.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string
};

export default ContactForm;
