import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import PropTypes from 'prop-types';

class Map extends Component {
  constructor(props) {
    super(props);

    this.makerLabelStyle = {
      backgroundColor: '#0f202c',
      color: '#fff',
      padding: '10px 20px',
      borderRadius: '10px'
    };

    this.mapStyles = [
      {
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#f5f5f5'
          }
        ]
      },
      {
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#616161'
          }
        ]
      },
      {
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'color': '#f5f5f5'
          }
        ]
      },
      {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#bdbdbd'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#eeeeee'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#757575'
          }
        ]
      },
      {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#e5e5e5'
          }
        ]
      },
      {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#9e9e9e'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#ffffff'
          },
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry.stroke',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.text',
        'stylers': [
          {
            'color': '#f7f9ff'
          }
        ]
      },
      {
        'featureType': 'road.arterial',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#757575'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#dadada'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#616161'
          }
        ]
      },
      {
        'featureType': 'road.local',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#9e9e9e'
          }
        ]
      },
      {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#e5e5e5'
          }
        ]
      },
      {
        'featureType': 'transit.station',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#eeeeee'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#c9c9c9'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#1a2f6c'
          }
        ]
      }
    ];
  }

  render() {

    const MyMap = compose(
      withProps({
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDkDumtke2faCGs32zyUlmTXobbic9cJYQ&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: '100%' }}/>,
        containerElement: <div className='map' style={{
          height: '700px',
          marginLeft: '-15px',
          marginRight: '-15px',
        }}/>,
        mapElement: <div style={{ height: '100%' }}/>
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: 50.989071, lng: 6.8876461 }}
        defaultOptions={{
          styles: props.styles,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false
        }}
      >
        {/* Siegburger Straße 149-151, 50679 Köln */}
        <MarkerWithLabel
          position={{ lat: 50.989071, lng: 6.8876461 }}
          labelAnchor={new google.maps.Point(50, 100)}
          icon={'assets/map-labal-arrow.png'}
          labelStyle={props.makerLabelStyle}
          onClick={() => window.open('https://www.google.de/maps/dir//Siegburger+Str.+149%2F151,+50679+Köln', '_blank')}
        >
          <div className='map-marker'>
            <h6>Impect GmbH</h6>
            <p className='m-0'>Siegburger Straße 149-151, 50679 Köln</p>
          </div>
        </MarkerWithLabel>
      </GoogleMap>
    ));

    return (
      <section className="map-section oblique">
        <div className="map-content oblique-content">
          <MyMap
            styles={this.mapStyles}
            makerLabelStyle={this.makerLabelStyle}
          />
        </div>
      </section>
    );
  }
}

Map.defaultProps = {
  height: '600px'
};

Map.propTypes = {
  height: PropTypes.string
};

export default Map;
